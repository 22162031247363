cockpitApp.controller('serviceAgreementDialogController', [
  '$rootScope', '$scope', '$mdDialog', '$anchorScroll', '$location',
  function($rootScope, $scope, $mdDialog, $anchorScroll, $location)
  {
    $scope.close = function() {
      $mdDialog.cancel();
    };

    $scope.displayTitles = function() {
      var carriers = [];

      angular.forEach($rootScope.basket.transfers, function(transfer) {
        if (carriers.indexOf(transfer.marketingCarrierCode) === -1) {
          carriers.push(transfer.marketingCarrierCode);
        }
      });

      return carriers.length > 1 || ($rootScope.basket.transfers && ($rootScope.basket.automaticCheckins || $rootScope.basket.insuranceBaggage))
    };

    $scope.scrollTo = function(elementId) {
      var id = $location.hash();

      $location.hash(elementId);
      $anchorScroll();
      $location.hash(id);
    };

    $scope.getBrandAutomaticCheckinServiceAgreement = function() {
      return '/pdf/aci-term/Sonderbedingungen_Auto_Check_In' + ($rootScope.booking && $rootScope.isInviaBrand() ? '_' + $rootScope.booking.branding : '') + '.pdf';
    };

    $scope.airlinesServiceAgreementLinks = [];

    $scope.getAirlinesServiceAgreementLinks = function() {
      angular.forEach($rootScope.booking.segments, function (segment) {
        if (!!segment.termsAndConditionlink) {
          const airline = {
            name : segment.marketingAirlineName,
            link : segment.termsAndConditionlink
          };
          $scope.airlinesServiceAgreementLinks.push(airline);
        }
      });
    };

    $scope.getAirlinesServiceAgreementLinks();
  }
]);
